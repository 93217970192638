import { Button, ButtonLink, ButtonsWrapper, ButtonText } from 'components/Button/Button';
import { ContainerItems } from 'components/ContainerItems/ContainerItems';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import QuoteIcon from '../assets/quote.svg';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';
import { useImageQuery } from '../hooks/use-image-query';
import { ContainerFlex } from '../theme/GlobalStyles';

const Contact: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const dataImage = useImageQuery();

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={dataImage.contact_bg.childImageSharp.fluid} imgStyle={{ objectPosition: 'top' }} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - Skontaktuj się ze mną</title>
            <meta name="description" content="Some content." />
          </Helmet>
          <SectionHeading>{formatMessage({ id: 'contact.title' })}</SectionHeading>
          <ContainerItems center>
            <SectionParagraph>{formatMessage({ id: 'contact.question' })}</SectionParagraph>
            <ButtonsWrapper>
              <ContainerFlex>
                <Button
                  as={ButtonLink}
                  href={`mailto:agnieszka.multirodzice@gmail.com`}
                  aria-label={formatMessage({ id: 'contact.me' })}
                >
                  <ButtonText>{formatMessage({ id: 'contact.me' })}</ButtonText>
                </Button>
              </ContainerFlex>
            </ButtonsWrapper>
          </ContainerItems>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default Contact;
